function filterSites() {

    var input, filter, table, tr, td, i;
    input = document.getElementById("filter-input");
    filter = input.value.toUpperCase();
    table = document.getElementById("sites-table");
    tr = table.getElementsByTagName("tr");

    // Loop through all table rows, and hide those who don't match the search query
    for (i = 0; i < tr.length; i++) {
        td = tr[i].getElementsByTagName("td")[0];
        if (td) {
            if (td.innerHTML.toUpperCase().indexOf(filter) > -1) {
                tr[i].style.display = "";
            } else {
                tr[i].style.display = "none";
            }
        }
    }
}


(function($) {
    $(document).on('ready', function() {
        $('.submit').on('click', function() {
            $('.loader').css('display', 'block');
        });

        $('[data-toggle="tooltip"]').tooltip();

        $('input[name="upload-options"]').change(function(){
            var $target = $("#" + $(this).data('target'));

            if($target.hasClass('collapse')){
                $('.control-group').addClass('collapse');
                $target.removeClass('collapse');
            }
        });
    });

})(jQuery);


( function ( document, window, index )
{
    var inputs = document.querySelectorAll( '.file-upload' );
    Array.prototype.forEach.call( inputs, function( input )
    {
        var label	 = input.previousElementSibling,
            labelVal = label.innerHTML;

        input.addEventListener( 'change', function( e )
        {
            var fileName = '';
            if( this.files && this.files.length > 1 )
                fileName = ( this.getAttribute( 'data-multiple-caption' ) || '' ).replace( '{count}', this.files.length );
            else
                fileName = e.target.value.split( '\\' ).pop();

            if( fileName )
                label.innerHTML = fileName;
            else
                label.innerHTML = labelVal;
        });

        // Firefox bug fix
        input.addEventListener( 'focus', function(){ input.classList.add( 'has-focus' ); });
        input.addEventListener( 'blur', function(){ input.classList.remove( 'has-focus' ); });
    });
}( document, window, 0 ));